<!--
 * @Author: your name
 * @Date: 2021-11-25 15:00:41
 * @LastEditTime: 2021-12-24 11:00:38
 * @LastEditors: Please set LastEditors
 * @Description: 方案架构
 * @FilePath: \new-website-1123\src\views\programme\pests\framework.vue
-->
<template>
  <div class="pests-framework">
    <p-title>
      方案
      <template v-slot:color>架构</template>
    </p-title>
    <div class="content-div">
      <div class="img-div">
        <span class="label-span">
          <slot name="label"></slot>
        </span>
        <img :src="url" alt="上太科技" />
      </div>
      <ul class="msg-div">
        <li v-for="item in dataList" :key="item.key">
          <span class="label-span">{{ item.label }}</span>
          <span class="desc-span">{{ item.desc }}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import pTitle from "./title.vue";
export default {
  name: "pests-framework",
  props: {
    url: {
      type: String,
      required: true,
    },
    dataList: {
      type: Array,
      required: true,
    },
  },
  components: {
    pTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.pests-framework {
  padding-top: 60px;
  padding-bottom: 40px;

  .content-div {
    margin: 50px auto 0;
    width: 78%;
    .img-div {
      display: flex;
      justify-content: center;
      background: #262626;
      padding-top: 15px;
      position: relative;
      height: 600px;
      .label-span {
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
      }
      img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
    .msg-div {
      width: 100%;
      padding: 0 50px;
      display: flex;
      justify-content: space-between;
      li {
        flex: 1;
        padding-top: 50px;
        padding-right: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        &::before {
          content: "";
          position: absolute;
          width: 3px;
          height: 50px;
          top: 0;
          left: 0;
          background-color: $color-active;
        }
        .label-span {
          font-size: 32px;
          font-weight: 400;
          color: #ffffff;
          margin-top: 20px;
        }
        .desc-span {
          font-size: 16px;
          font-weight: 400;
          color: rgba($color: #fff, $alpha: 0.5);
          margin-top: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .content-div {
      width: 100%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .pests-framework {
    padding-top: 40px;
    padding-bottom: 40px;

    .content-div {
      margin: 20px auto 0;
      width: 86%;
      .img-div {
        display: flex;
        justify-content: center;
        background: #262626;
        padding-top: 15px;
        position: relative;
        height: 5.45rem;
        .label-span {
          position: absolute;
          top: 0.1rem;
          left: 50%;
          transform: translateX(-50%);
        }
        img {
          max-width: 100%;
          height: 100%;
          object-fit: contain;
          display: block;
        }
      }
      .msg-div {
        width: 100%;
        padding: 0 6vw;
        flex-wrap: wrap;
        // justify-content: space-between;
        li {
          flex: unset;
          width: 48%;
          padding-top: 10px;
          margin-top: 20px;
          &::before {
            content: "";
            position: absolute;
            width: 50px;
            height: 3px;
            top: 0;
            left: 0;
            background-color: $color-active;
          }
          .label-span {
            font-size: 0.29rem;
            font-weight: 400;
            color: #ffffff;
            margin-top: 5px;
          }
          .desc-span {
            font-size: 0.15rem;
            margin-top: 6px;
          }
        }
      }
    }
  }
}
</style>
